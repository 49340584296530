@use 'sass:math';

@import 'styles/_helpers';

.videoplayer {
    $this: &;

    &__video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 24px;
        transition: transform 0.2s;
        cursor: pointer;
        overflow: hidden;
    }

    &__caption {
        @include font-size(base);
        color: $color-muted;
        margin-top: $spacing--small;

        @include min-width(xl) {
            max-width: 500px;
        }
    }

    &__title {
        @include font-size(base);
        position: absolute;
        z-index: 2;
        color: $color-light;
        margin: 0;
        bottom: $spacing--small;
        left: $spacing--small;
        text-align: left;
        text-wrap: balance;
    }

    &__trigger {
        display: block;
        position: relative;
        width: 100%;
        border-radius: $border-radius--large;
        background-size: cover;
        background-position: center bottom;
        background-color: color(neutral, xdark);
        aspect-ratio: 3/2;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0.75;
            border-radius: inherit;
            background-color: black;
        }

        &:hover,
        &:focus {
            background-color: black;
        }
    }

    &__play {
        $size: 64px;
        position: absolute;
        z-index: 2;
        top: calc(50% - #{math.div($size, 2)});
        left: calc(50% - #{math.div($size, 2)});
        display: flex;
        justify-content: center;
        align-items: center;
        width: $size;
        height: $size;
        padding-left: 1px;
        border-radius: $border-radius--small;
        color: $color-text;
        background-color: color(primary);
    }

    &--aspect {
        #{$this}__trigger {
            aspect-ratio: var(--aspect);
        }
    }
}
