@import 'styles/_helpers';

.button {
    $this: &;
    --select-stroke: 5px;

    @include min-width(md) {
        --select-stroke: 6px;
    }

    @include font(base, normal);
    @include font-size(base);
    display: inline-block;
    padding: 15px 20px;
    text-align: center;
    text-decoration: none;
    border: 0;
    border-radius: $border-radius;
    background-color: color(neutral, light);

    &:hover,
    &:focus:focus-visible {
        background-color: color(neutral);
    }

    @include min-width(lg) {
        padding: 18px 26px;
    }

    &__icon {
        flex-shrink: 0;

        &:not(:only-child) {
            margin-left: 1em;
        }
    }

    &--primary {
        color: $color-text;
        background-color: color(primary);

        &:hover,
        &:focus:focus-visible {
            color: $color-text;
            background-color: color(primary, dark);
        }
    }

    &--circle {
        display: flex;
        justify-content: center;
        align-items: center;
        width: var(--circle-size);
        height: var(--circle-size);
        overflow: hidden;
        padding: 0;
        border-radius: $border-radius--small;

        #{$this}__icon {
            margin: 0;
        }

        #{$this}__text {
            @include screenreaders-only;
        }
    }

    &--tag {
        @include font-size(base);
        padding: 8px 20px;
        margin: 6px 6px 0 0;
        background-color: color(primary);
        border-radius: $border-radius--small;

        &:hover,
        &:focus:focus-visible {
            background-color: color(primary);
            box-shadow: inset 0 0 0 30px rgba(black, 0.05);
        }
    }

    &--large {
        @include font-size(sub-title);
        padding: 15px 20px;

        @include min-width(md) {
            padding: 19px 32px;
        }
    }

    &--left {
        display: flex;
        text-align: left;
        justify-content: space-between;
    }

    &--selected {
        box-shadow: inset 0 0 0 var(--select-stroke) rgba(black, 0.1);

        &:hover,
        &:focus:focus-visible {
            background-color: color(neutral, light);
        }
    }

    &--small {
        --select-stroke: 5px;
        @include font-size(small);
        border-radius: $border-radius--small;
        padding: 11px 16px;

        @include min-width(md) {
            padding: 11px 20px;
        }
    }

    &--xsmall {
        @include font-size(small);
        border-radius: $border-radius--small;
        height: var(--circle-size);
        display: inline-flex;
        align-items: center;
        padding: 0 14px;
        overflow: hidden;
    }

    &--more {
        min-width: 8rem;
    }

    &--full {
        width: 100%;
    }
}
