@import 'styles/_helpers';

.image {
    $this: &;
    align-self: start;

    &__aspect {
        position: relative;
        width: 100%;
        border-radius: $border-radius--large;
        background-size: cover;

        &--expandable {
            cursor: pointer;
        }

        &--square {
            border-radius: $border-radius--small;
        }
    }

    &__image {
        display: block;
        object-fit: fill;
        width: 100%;
        height: 100%;
        border-radius: inherit;
    }

    &__caption {
        @include font-size(base);
        color: $color-muted;
        margin-top: $spacing--small;

        @include min-width(xl) {
            max-width: 500px;
        }
    }

    &__download {
        display: block;
        margin-top: $spacing--xsmall;
    }

    &--full {
        width: var(--image-full, 100%);
    }

    &--facsimile {
        padding: 2rem;
        border-radius: $border-radius--xlarge;
        color: $color-dark;
        background-color: color(primary, light);

        @include min-width(md) {
            padding: 3rem;
        }

        @include min-width(xl) {
            padding: 5rem;
        }

        #{$this}__caption {
            color: inherit;
            margin-bottom: -0.8em; // Optical
        }

        #{$this}__aspect,
        #{$this}__image {
            border-radius: $border-radius--xsmall;
        }
    }

    &--facsimile-tight {
        display: grid;
        padding: 1rem;
        border-radius: $border-radius--large;
        color: $color-dark;
        aspect-ratio: 1/1;
        background-color: color(primary, light);

        @include min-width(md) {
            padding: 2rem;
        }

        @include min-width(xl) {
            padding: 3rem;
        }

        #{$this}__caption {
            color: inherit;
            margin-top: auto; // Optical
            margin-bottom: -0.8em; // Optical
        }

        #{$this}__aspect {
            margin: auto 0;
        }

        #{$this}__aspect,
        #{$this}__image {
            border-radius: $border-radius--xsmall;
        }
    }
}
