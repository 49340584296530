@import 'styles/_helpers';

.modal {
    $this: &;
    $max-width: 100%;
    $max-width--lg: calc(100% - (#{$margin} * 4));
    $max-width--lg-alternative: 1220px;
    $max-width--tight: 900px;
    $max-width--article: 1260px;

    display: flex;
    justify-content: center;
    align-items: flex-start;

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: $level-4;
    padding: $margin;
    padding-top: calc(#{$margin} + 38px + #{$spacing});
    overflow-y: auto;
    overscroll-behavior: contain;
    -webkit-overflow-scrolling: touch;

    &__backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $color-backdrop;

        animation: fade-in 500ms $ease-in-out-quint--custom both;

        &--animate-out {
            animation: fade-out 500ms $ease-in-out-quint--custom forwards;
        }

        &--prevent-close {
            cursor: default;
        }
    }

    &__title {
        @include font-size(title);
        text-align: center;
    }

    &__content {
        position: relative;
        width: 100%;
        max-width: $max-width;
        z-index: 2;
        outline: 0;
        border-radius: $border-radius--small;
        background-color: $color-dark;
        box-shadow: $shadow;

        @include min-width(lg) {
            max-width: $max-width--lg;

            // TODO: Re-evaluate
            @media (max-height: 1000px) {
                max-width: $max-width--lg-alternative;
            }
        }
        &--autoWidth {
            width: auto;
        }
    }

    &__close {
        position: fixed;
        z-index: $level-4;
        right: $margin;
        top: $margin;

        animation: modal-close-enter 400ms $ease-out-quart both;
        animation-delay: 500ms;
        will-change: transform, opacity;

        &--animate-out {
            animation: modal-close-exit 500ms $ease-in-out-quint--custom forwards;
            animation-delay: 0;
        }
    }

    &__hidden-close {
        position: absolute;
        width: 0;
        height: 0;
        flex-grow: 0;
    }

    &--transparent {
        #{$this}__content {
            background-color: transparent;
        }
    }

    &--article {
        #{$this}__content {
            padding: $margin;
            background-color: $color-background;

            @include min-width(lg) {
                max-width: $max-width--article;
            }
        }
    }

    &--slides {
        #{$this}__content {
            border-radius: $border-radius;
            background-color: $color-background;

            @include min-width(lg) {
                max-width: $max-width--article;
            }
        }
    }

    &--tight {
        #{$this}__content {
            @include min-width(lg) {
                max-width: $max-width--tight;
            }
        }
    }
}

@keyframes modal-close-enter {
    from {
        opacity: 0;
        transform: scale(0.6);
    }
}

@keyframes modal-close-exit {
    to {
        opacity: 0;
        transform: scale(0.6);
    }
}

// No custom antions, only very basic ones
@keyframes fade-in {
    from {
        opacity: 0;
    }
}

@keyframes fade-out {
    to {
        opacity: 0;
    }
}
