@import 'styles/_helpers';

.quiz {
    $this: &;
    $large-breakpoint: map-get($breakpoints, lg);

    $control-size: 38px;
    $controls-height: calc(#{$margin} + #{$control-size});
    position: fixed;
    z-index: $level-3;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    background-color: $color-background;

    &__title {
        @include font(base, bold);
        @include font-size(xl);
        text-align: center;
        margin-top: auto;
        margin-bottom: $spacing--large;
        max-width: 26ch;

        &--intro {
            min-height: 0;
            margin-top: 0;
            margin-bottom: $spacing;
        }

        @include entering {
            animation: title-enter 600ms $ease-in-out-quint--custom both;
            animation-delay: 300ms;
        }

        @include exiting {
            animation: title-exit 800ms $ease-in-out-quint--custom forwards;
        }
    }

    &__preamble {
        @include font-size(title);
        max-width: 50rem;
        text-align: center;
        margin-bottom: $spacing--large;

        &:last-child {
            margin-bottom: 0;
        }

        @include entering {
            animation: title-enter 600ms $ease-in-out-quint--custom both;
            animation-delay: 300ms;
        }

        @include exiting {
            animation: title-exit 800ms $ease-in-out-quint--custom forwards;
        }
    }

    &__answer-box-wrapper {
        position: relative;
    }

    &__answer-box {
        @include font-size(title-large);
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: $controls-height;
        padding: 0 $margin;
        display: flex;
        justify-content: space-between;
        z-index: $level-4;
        align-items: center;
        background-color: $color-warning;

        &--wrong {
            background-color: $color-warning;

            #{$this}__button {
                &,
                &:hover,
                &:focus {
                    background-color: $color-warning--dark;
                }
            }
        }

        &--correct {
            background-color: $color-success;

            #{$this}__button {
                &,
                &:hover,
                &:focus {
                    background-color: $color-success--dark;
                }
            }
        }

        @include entering {
            animation: answerbox-enter 500ms $ease-in-out-quint--custom backwards;
        }

        @include exiting {
            animation: answerbox-exit 500ms $ease-in-out-quint--custom forwards;
        }
    }

    &__wrapper {
        position: relative;
        height: 100%;
    }

    &__step,
    &__intro {
        display: flex;
        align-items: center;
        flex-direction: column;
        min-height: 100%;
        padding: $margin;
        padding-top: $controls-height;
    }

    &__intro {
        justify-content: center;
    }

    &__controls {
        position: absolute;
        display: flex;
        justify-content: space-between;
        z-index: 3;
        top: 0;
        left: 0;
        width: 100%;
        padding: $margin $margin 0;
        height: $controls-height;
    }

    &__nav {
        display: flex;
        align-items: center;
        gap: $spacing--small;
        animation: title-enter 600ms $ease-in-out-quint--custom both;
    }

    &__control {
        display: flex;
        justify-content: center;
        align-items: center;
        width: $control-size;
        height: $control-size;
        border-radius: $border-radius--small;
        color: $color-text;
        background-color: color(primary, light);

        outline: 0;
        transition:
            transform 150ms $ease-out-quart,
            box-shadow 200ms $ease-out-quart;

        &:hover {
            background-color: color(primary);
        }

        &:active {
            transform: scale(0.95);
        }

        &:focus {
            box-shadow: 0 0 0 $border-width $color-outline;
        }

        &--right {
            margin-left: auto;
        }

        &--back {
            padding-right: 2px;
        }
    }

    &__media {
        width: 100%;
        max-width: 620px;
        margin-bottom: $spacing--large;

        @include entering {
            animation: title-enter 600ms $ease-in-out-quint--custom both;
            animation-delay: 300ms;
        }

        @include exiting {
            animation: title-exit 800ms $ease-in-out-quint--custom forwards;
        }
    }

    &__options {
        display: grid;
        margin-top: auto;
        grid-gap: $spacing--small;
        width: 100%;

        @include min-width(lg) {
            grid-template-columns: 1fr 1fr;
        }

        &--odd {
            grid-template-columns: 1fr;

            @include min-width(xl) {
                grid-template-columns: 1fr 1fr 1fr;
            }
        }
    }

    &__option {
        position: relative;
        padding: 16px 22px;
        border-radius: $border-radius;
        background-color: color(primary, light);

        @include min-width(md) {
            padding: 28px 48px;
        }

        @include entering {
            animation: option-enter 500ms $ease-in-out-quint--custom both;
            @include animation-delay(12, 80ms, 300ms);
        }

        @include exiting {
            animation: option-exit 500ms $ease-in-out-quint--custom forwards;
            @include animation-delay(12, 60ms, 0);
        }

        &:hover,
        &:focus {
            background-color: color(primary);
        }

        &--promoted {
            @include font(base, bold);
            @include font-size(sub-title);
            padding: 20px 36px;

            @include min-width(md) {
                padding: 28px 58px;
            }
        }

        &--restart,
        &--results {
            @include font(base, normal);
            @include font-size(sub-title);
            min-width: 14rem;
            margin-bottom: $section-spacing--small;
        }

        &--restart {
            margin-top: $section-spacing--small;
            margin-bottom: 0;
        }

        &--selected {
            &,
            &:hover,
            &:focus {
                color: color(primary, light);
                background-color: $color-text;
                animation-delay: 400ms !important;
            }
        }

        &--wrong {
            &,
            &:hover,
            &:focus {
                color: $color-text;
                background-color: $color-warning;
            }
        }

        &--correct {
            &,
            &:hover,
            &:focus {
                color: $color-text;
                background-color: $color-success;
            }
        }
    }

    &__selected-icon {
        position: absolute;
        display: flex;
        align-items: center;
        top: 0;
        right: 20px;
        height: 100%;
    }

    &__question {
        position: relative;
        height: 100%;

        @include exiting(true) {
            pointer-events: none;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
        }
    }

    &__score {
        text-align: center;
        padding: $margin;
        padding-top: $controls-height;
    }

    &__score-card {
        @include font-size(title);
        position: relative;
        display: flex;
        margin-top: $spacing;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        text-align: center;
        height: calc(100vh - (#{$margin} * 2) - #{$controls-height} - 8rem);
        margin-bottom: $section-spacing--small;
        border-radius: $border-radius--large;
        overflow: hidden;
        background-color: color(primary, light);

        @include min-width(md) {
            height: 560px;
        }

        @include min-width(xl) {
            height: 700px;
        }

        @include entering {
            animation: option-enter 600ms $ease-in-out-quint--custom both;
            animation-delay: 300ms;
        }

        @include exiting {
            animation: option-exit 500ms $ease-in-out-quint--custom forwards;
        }

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: var(--score);
            border-radius: $border-radius--large 0 0 $border-radius--large;
            background-color: color(primary);

            animation: progress-buildup 2000ms $ease-in-out-quint--custom both;
            animation-delay: 1000ms;
        }

        // &--good {
        //     background-color: $color-success;
        // }

        // &--bad {
        //     background-color: $color-warning;
        // }
    }

    &__score-card-content {
        position: relative;
        z-index: 2;
    }

    &__summary-list {
        display: flex;
        flex-direction: column;
    }

    &__summary-item {
        max-width: 42rem;
        margin: $section-spacing--small auto 0;
        display: flex;
        flex-direction: column;

        @include entering {
            animation: option-enter 600ms $ease-in-out-quint--custom both;
            @include animation-delay(12, 80ms, 300ms);
        }

        @include exiting {
            animation: option-exit 500ms $ease-in-out-quint--custom forwards;
            @include animation-delay(12, 60ms, 0);
        }
    }

    &__summary-answer {
        position: relative;
        padding: 16px 32px;
        border-radius: $border-radius;
        background-color: color(neutral, light);

        &:not(:last-child) {
            margin-bottom: 6px;
        }

        &--wrong {
            background-color: $color-warning;
        }

        &--correct {
            background-color: $color-success;
        }
    }

    &__result {
        @include font-size(xl);
        margin-bottom: $spacing;
    }

    &__sub-title {
        @include font(base, normal);
        @include font-size(sub-title);
        text-align: left;
        margin-bottom: $spacing;

        @include min-width(md) {
            display: grid;
            grid-template-columns: 1fr 2fr;
        }
    }

    &__index {
        display: block;
    }

    &__answer-icon {
        position: absolute;
        right: 18px;
        top: calc(50% - 6px);
    }

    // Entry stuff
    &__entry {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 0 $spacing--small $spacing--small;
        border-radius: $border-radius--large;
        background-color: color(primary, light);
        aspect-ratio: 1/1;

        animation: entry-enter 600ms $ease-in-out-quint--custom backwards;
        @include animation-delay(10, 50ms);

        &:hover,
        &:focus:focus-visible {
            background-color: color(primary);

            #{$this}__tag {
                background-color: color(primary, light);
            }
        }
    }

    &__illustration {
        margin: 0 auto $spacing--small;
        max-width: 4rem;

        @include min-width(md) {
            max-width: 6.6rem;
        }

        @media screen and (min-width: $large-breakpoint) and (max-height: 860px) {
            display: none;
        }

        @media screen and (min-width: $large-breakpoint) and (max-height: 1000px) {
            max-width: 5rem;
        }

        &--entry {
            display: block;
            max-width: 6.8rem;
            margin: auto;
        }
    }

    &__tag {
        @include font-size(small);
        position: absolute;
        top: 12px;
        right: 12px;
        padding: 4px 10px 3px;
        border-radius: $border-radius--small;
        background-color: color(primary);
        transition: background-color 200ms $ease-out-quart;

        @include min-width(md) {
            padding: 6px 12px 5px;
            top: $spacing--small;
            right: $spacing--small;
        }
    }

    &--no-scroll {
        overflow: hidden;
    }

    &--fluid {
        position: relative;
        border-radius: $border-radius--small;
        background-color: $color-background;

        #{$this}__intro,
        #{$this}__step {
            min-height: 70vh;
        }

        #{$this}__answer-box {
            position: absolute;
            border-top-left-radius: $border-radius;
            border-top-right-radius: $border-radius;
        }

        #{$this}__score-card {
            height: max(300px, 40vh);
            margin-top: 0;
        }

        #{$this}__score {
            padding-top: $margin;
        }
    }
}

@keyframes answerbox-enter {
    // from {
    //     transform: translateY(-100%);
    // }

    from {
        clip-path: inset(0 0 100% 0 round #{$border-radius});
    }

    to {
        clip-path: inset(0 0 0 0 round 0);
    }
}

@keyframes answerbox-exit {
    from {
        clip-path: inset(0 0 0 0 round 0);
    }

    to {
        clip-path: inset(0 0 100% 0 round #{$border-radius});
    }
}

@keyframes option-enter {
    from {
        clip-path: inset(100% 100% 0 0 round #{$border-radius});
    }

    to {
        clip-path: inset(0 0 0 0 round #{$border-radius});
    }
}

@keyframes option-exit {
    from {
        clip-path: inset(0 0 0 0 round #{$border-radius});
    }

    to {
        clip-path: inset(100% 0 0 100% round #{$border-radius});
    }
}

@keyframes title-enter {
    from {
        clip-path: inset(100% 100% 0 0 round #{$border-radius});
    }

    to {
        clip-path: inset(0 0 0 0 round 0);
    }
}

@keyframes title-exit {
    from {
        clip-path: inset(0 0 0 0 round #{$border-radius});
    }

    to {
        clip-path: inset(100% 0 0 100% round 0);
    }
}

@keyframes progress-buildup {
    from {
        clip-path: inset(0 100% 0 0 round #{$border-radius});
    }

    to {
        clip-path: inset(0 0 0 0 round 0);
    }
}

@keyframes entry-enter {
    from {
        clip-path: inset(100% 100% 0 0 round #{$border-radius--small});
    }

    to {
        clip-path: inset(0 0 0 0 round #{$border-radius--small});
    }
}
